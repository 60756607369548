import React from 'react'

function Notfound() {
  return (
    <div>
<div id="notfound">
      <div className="notfound">
        <div className="notfound-404">
          <h3>Oops! Page not found</h3>
          <h1>
            <span>4</span>
            <span>0</span>
            <span>4</span>
          </h1>
        </div>
        {/* <h2>We are sorry, but the page you requested was not found</h2> */}
        <h3>We are sorry, but the page you requested was not found</h3>
      </div>
    </div>


    </div>
  )
}

export default Notfound